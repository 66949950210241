<nav class="navbar navbar-vertical navbar-expand-md navbar-light" id="sidenav-main">
  <div class="container-fluid">
    <button id="sideNav" aria-label="sideNav" class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
      aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="d-md-none d-lg-none">
      <a class="navbar-brand pt-0">
        <img alt="logotipo" [src]="logo" class="navbar-brand-img" />
      </a>
    </div>
    <div class="d-none d-md-inline-block d-lg-inline-block" style="
        margin-left: -30px;
        margin-right: -24px;
        height: 190px;
        background-color: {{ style }}
      ">
      <a class="navbar-brand pt-0" ngbDropdown placement="bottom-right">
        <img alt="Logo" class="img-profile rounded-circle" src="assets/img/svg/img-profile.svg" ngbDropdownToggle />
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <button class="dropdown-item" (click)="profile()"><em class="fas fa-user mr-2"></em>Mi Perfil</button>
          <button class="dropdown-item" (click)="password()"><em class="fas fa-key mr-2"></em>Cambiar
            Contraseña</button>
        </div>
      </a>
      <p class="text-center text-white mb-1 font-weight-bold">
        ¡Hola {{ nameProfile }}!
      </p>
      <p class="text-center text-white">
        <small>{{ state }}</small>
      </p>
    </div>
    <ul class="nav align-items-center d-md-none">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link nav-link-icon" role="button" ngbDropdownToggle>
          <em class="ni ni-bell-55"></em>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)">Action</a>
          <a class="dropdown-item" href="javascript:void(0)">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
        </div>
      </li>
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Perfil" src="assets/img/svg/img-profile.svg" />
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <p class="ml-3 mb-0 text-secondary">{{ nameProfile }}</p>
          <a class="dropdown-item" (click)="profile()" class="dropdown-item">
            <em class="fa fa-user-plus mr-2" style="color: {{ style }}"></em>
            <span>Editar mi perfil</span>
          </a>
          <a class="dropdown-item" (click)="password()" class="dropdown-item">
            <em class="fa fa-user-secret mr-2" style="color: {{ style }}"></em>
            <span>Cambiar contraseña</span>
          </a>
          <a [routerLink]="[]" (click)="closeLogout()" class="dropdown-item">
            <em class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-danger"></em>
            <span>Cerrar sesión</span>
          </a>
        </div>
      </li>
    </ul>
    <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a routerLinkActive="active">
              <img [src]="logo" alt="Logo" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button id="idLogo" aria-label="logo" type="button" class="navbar-toggler"
              (click)="isCollapsed = !isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="[]" (click)="routerName('Inicio')">
            <em class="fas fa-home"></em>
            Inicio
          </a>
        </li>
        <ng-container *ngIf="idUser === 3">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="[]" data-toggle="collapse" data-target="#collapseSchoolServices">
              <em class="fa fa-qrcode"></em>
              QR
            </a>
            <div id="collapseSchoolServices" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/scan-qr']" (click)="routerName('Scanner QR')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Scanner QR
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="idUser === 1">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="[]" data-toggle="collapse" data-target="#collapseSchoolServices">
              <em class="far fa-calendar-alt"></em>
              Eventos
            </a>
            <div id="collapseSchoolServices" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/event']" (click)="routerName('Eventos')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Consulta de eventos
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="idUser === 2">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapseSales">
              <em class="fas fa-dollar-sign"></em>
              Ventas
            </a>
            <div id="collapseSales" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a *ngIf="idUser === 2" class="collapse-item" [routerLink]="['/select-enclosure']"
                  (click)="routerName('Seleccionar Recinto')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Seleccionar Recinto
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="idUser === 1">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapseSales">
              <em class="fas fa-dollar-sign"></em>
              Ventas
            </a>
            <div id="collapseSales" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/general-report']"
                  (click)="routerName('Reporte General por evento')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reporte eventos
                </a>
                <a class="collapse-item" [routerLink]="['/reports']" (click)="routerName('Reportes Usuarios')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reportes Usuarios
                </a>
                <a class="collapse-item" [routerLink]="['/reports-zone-vip']" (click)="routerName('Reportes Zona VIP')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reportes de zonas VIP
                </a>
                <a *ngIf="idAdmin === 1 || idAdmin === 5" class="collapse-item"
                  [routerLink]="['/prepaid-history-reports']" (click)="routerName('Reportes de Usuarios Prepago')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reportes de Usuarios Prepago
                </a>
                <a *ngIf="idAdmin === 1 || idAdmin === 5" class="collapse-item" [routerLink]="['/reports-account']"
                  (click)="routerName('Reporte Estado Cuenta CARE')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reporte Estado Cuenta CARE
                </a>
                <a *ngIf="idAdmin === 1" class="collapse-item" [routerLink]="['/reports-infinity']"
                  (click)="routerName('Reporte Estado VIP')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reporte Estado VIP
                </a>
                <a *ngIf="idAdmin === 1" class="collapse-item" [routerLink]="['/report-by-month']"
                  (click)="routerName('Reporte por mes')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reporte por mes
                </a>
                <a *ngIf="idAdmin === 1 || idAdmin === 5" class="collapse-item"
                  [routerLink]="['/report-by-closing-month']" (click)="routerName('Reporte cierre de mes')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Reporte cierre de mes
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="idUser === 1">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapseAdmin">
              <em class="fas fa-toolbox"></em>
              Administración
            </a>
            <div id="collapseAdmin" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/users']" (click)="routerName('Administracion')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Usuarios
                </a>
                <a class="collapse-item" [routerLink]="['/parkingArea']" (click)="routerName('Zonas')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Zonas
                </a>
                <ng-container *ngIf="nameProfile === 'Administrador'">
                  <a class="collapse-item" [routerLink]="['/enclosures']" (click)="routerName('Recintos')">
                    <em class="far fa-circle mr-2 text-danger"></em>
                    Recintos
                  </a>
                </ng-container>
                <ng-container *ngIf="nameProfile === 'Administrador'">
                  <a class="collapse-item" [routerLink]="['/eventType']" (click)="routerName('Tipos de evento')">
                    <em class="far fa-circle mr-2 text-danger"></em>
                    Tipos de evento
                  </a>
                </ng-container>
                <ng-container *ngIf="nameProfile === 'Administrador'">
                  <a class="collapse-item" [routerLink]="['/discount-code']"
                    (click)="routerName('Codigo de Descuento')">
                    <em class="far fa-circle mr-2 text-danger"></em>
                    Código de descuento
                  </a>
                </ng-container>
                <a class="collapse-item" [routerLink]="['/generate-qr']" (click)="routerName('Generacion de QR')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Generacion de QR
                </a>
                <a class="collapse-item" [routerLink]="['/qrabonados']" (click)="routerName('Generacion de QR')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  QR Abonados
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="(idUser === 1 && isPrepaid) || nameProfile === 'Administrador'">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapsePrepaid">
              <em class="fas fa-wallet"></em>
              Prepago
            </a>
            <div id="collapsePrepaid" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/type-prepaid']" (click)="routerName('Prepago')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Tipos de prepago
                </a>
                <a class="collapse-item" [routerLink]="['/prepaid-create']" (click)="routerName('Crear Perfil')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Dar de alta nuevo perfil
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="(idUser === 1 && isPrepaid) || nameProfile === 'Administrador'">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapseAdminPrepaid">
              <em class="fas fa-wallet"></em>
              Administración de usuarios prepago
            </a>
            <div id="collapseAdminPrepaid" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/generateQrToUser']" (click)="routerName('Zonas')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Dar de alta QR's
                </a>
                <a class="collapse-item" [routerLink]="['/change-qr-type']" (click)="routerName('Administracion')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Cambiar tipo de QR
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="idUser === 2 && isEvent">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapseAdmin">
              <em class="fas fa-shopping-cart"></em>
              Mis Eventos
            </a>
            <div id="collapseAdmin" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/user-tickets']" (click)="routerName('Mis Eventos')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Eventos
                </a>
              </div>
            </div>
          </li>
        </ng-container>
        <ng-container *ngIf="idUser === 2 && isPrepaid">
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#collapsePrepago">
              <em class="fas fa-wallet"></em>
              Prepago
            </a>
            <div id="collapsePrepago" class="collapse">
              <div class="bg-light py-2 collapse-inner rounded">
                <a class="collapse-item" [routerLink]="['/user-prepaid']" (click)="routerName('Consultar QR')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Consultar/Recargar QR
                </a>
                <a class="collapse-item" [routerLink]="['/user-prepaid-history']"
                  (click)="routerName('Historial de movimientos')">
                  <em class="far fa-circle mr-2 text-danger"></em>
                  Historial
                </a>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="btn-closeLogout d-flex align-items-end mb-3">
      <button type="button" class="btn btn-block btn-danger btn-lg shadow btn-circle" (click)="closeLogout()">
        <small>
          Salir
          <em class="fas fa-sign-in-alt ml-2"></em>
        </small>
      </button>
    </div>
  </div>
</nav>