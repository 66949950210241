export interface GenerarQRPrepaidRequestDTO {
  QR: number;
  evento: number;
  zona: number;
}

export interface GenerarTypePrepaidResponseDTO {
  result: string;
  response: RequestDTO[];
  message: any;
}

export interface RequestDTO {
  chrName: string;
  diasActividad: string;
  horarioActividad: string;
  vigencia: Date;
  minimumRecharge: string;
  pricePerHour: string;
  maximumPrice: string;
  toleranceTime: string;
  idRecinto: number;
}

export interface ListarTypesPrepaidRequestDTO {
  id: any;
}

export interface ListarTypesPrepaidResponseDTO {
  result: string;
  response: TypesPrepaidDTO[];
}

export interface TypesPrepaidDTO {
  id: number;
  chrName: string;
  diasActividad: string;
  horarioActividad: string;
  vigencia: Date;
  minimumRecharge: string;
  pricePerHour: string;
  maximumPrice: string;
  toleranceTime: string;
  idRecinto: number;
  status: boolean;
}

export interface AsignarQRPrepaidRequestDTO {
  id: number;
  nombreUsuario: string;
  activarQR: boolean;
}

export interface AsignarQRPrepaidResponseDTO {
  result: string;
  message: string;
}

export interface GetAllTypeOfPrepaidRequestDTO {
  admin: any;
}

export interface GetAllTypeOfPrepaidResponseDTO {
  id: number;
  chrName: string;
  diasActividad: string;
  horarioActividad: any;
  vigencia: any;
  minimumRecharge: number;
  pricePerHour: number;
  maximumPrice: number;
  toleranceTime: number;
  idRecinto: number;
  status: boolean;
}

export interface LeerQrPrepaidRequestDTO {
  id: any;
}

export interface LeerQRPrepaidDTO {
  result: string;
  eventDate: ListarTypesPrepaidResponseDTO[];
}

export interface RegistrarQRPrepaidRequestDTO {
  idUsuario: number;
  idFolioBoleto: number;
  chrCodigoQR: string;
  consultaAt: Date;
}

export interface RegistrarQRPrepaidResponseDTO {
  result: string;
  message: string;
  response: RespuestaDTO;
}

export interface RespuestaDTO {
  id: number;
  intFKIDUsuario: number;
  intFKIDQRPrepaid: number;
  chrCodigoQR: string;
  consultaAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface DeleteTypePrepaidRequestDTO {
  id: number;
}

export interface DeleteQRPrepaidResponseDTO {
  result: string;
  message: string;
}

export interface UpdateQRPrepaidRequestDTO {
  idQR: any;
  idType: any;
}

export interface UpdateQRPrepaidResponseDTO {
  result: string;
  response: string;
}

export interface ReportsRequestDTO {
  admin: any;
  tipo: any;
  email?: string;
  intIDUsuario?: string;
  fechaInicio: string;
  fechaFin: string;
  limit: any;
  offset: any;
  page: any;
}

export interface ReportsTotalRequestDTO {
  admin: any;
  fechaInicio: string;
  fechaFin: string;
  limit: any;
  offset: any;
  page: any;
}

export interface ReportsHistoryRequestDTO {
  admin: any;
  email: string;
  tipo: any;
  limit: any;
  offset: any;
  page: any;
}

export interface ReportsResponseDTO {
  result: string;
  response: ReportResponse[];
}
export interface ReportResponse {
  chrCodigoQR: string;
  chrCorreo: string;
  chrNombreRecinto: string;
  nombreTipo: string;
  idCodigoQr: string;
  amountTotal: number;
  chargeTotal: number;
  balanceTotal: number;
}

export interface CardRequestDTO {
  id: any;
  precio: string;
  card: string;
  code: string;
  mes: string;
  algo: string;
  user: string;
}

export interface CardResponseDTO {
  clientRequestId: string;
  apiTraceId: string;
  responseType: string;
  ipgTransactionId: string;
  orderId: string;
  transactionType: string;
  paymentToken: PaymentToken;
  transactionOrigin: string;
  paymentMethodDetails: PaymentMethodDetails;
  country: string;
  terminalId: string;
  merchantId: string;
  transactionTime: number;
  transactionStatus: string;
  approvalCode: string;
  secure3dResponse: Secure3DResponse;
  authenticationResponse: AuthenticationResponse;
  processor: Processor;
  message: string;
  response: Response;
  data: Response;
}

export interface PaymentToken {
  reusable: boolean;
  declineDuplicates: boolean;
  brand: string;
  type: string;
}

export interface PaymentMethodDetails {
  paymentCard: PaymentCard;
  paymentMethodType: string;
}

export interface Secure3DResponse {
  responseCode3dSecure: string;
}

export interface AuthenticationResponse {
  type: string;
  version: string;
  params: Params;
  secure3dMethod: Secure3dMethod;
}

export interface Response {
  clientRequestId: string;
  apiTraceId: string;
  ipgTransactionId: string;
  orderId: string;
  transactionType: string;
  paymentToken: PaymentToken;
  transactionOrigin: string;
  paymentMethodDetails: PaymentMethodDetails;
  country: string;
  transactionTime: number;
  transactionStatus: string;
  approvalCode: string;
  authenticationResponse: AuthenticationResponse;
  secure3dResponse: Secure3DResponse;
  error: boolean;
  declined: boolean;
  associationResponseCode: string;
  last4: string;
  total: string;
}

export interface Processor {
  referenceNumber: string;
  authorizationCode: string;
  responseCode: string;
  network: string;
  associationResponseCode: string;
  responseMessage: string;
  avsResponse: AvsResponse;
  securityCodeResponse: string;
}

export interface PaymentCard {
  expiryDate: ExpiryDate;
  bin: string;
  last4: string;
  brand: string;
}

export interface Params {
  payerAuthenticationRequest: string;
  termURL: string;
  merchantData: string;
  acsURL: string;
  cReq: string;
}

export interface Secure3dMethod {
  methodForm: string;
  secure3dTransId: string;
}

export interface AvsResponse {
  streetMatch: string;
  postalCodeMatch: string;
}

export interface ExpiryDate {
  month: string;
  year: string;
}

export interface CardParcheV2RequestDTO {
  folio: any;
}

export interface CardParche2V2RequestDTO {
  folio: any;
  cReq: any;
}

export interface CardParcheV2ResponseDTO {
  message: string;
  data: ResponseParche;
}

export interface ResponseParche {
  clientRequestId: string;
  apiTraceId: string;
  ipgTransactionId: string;
  orderId: string;
  transactionType: string;
  paymentToken: PaymentToken;
  transactionOrigin: string;
  paymentMethodDetails: PaymentMethodDetails;
  country: string;
  terminalId: string;
  merchantId: string;
  transactionTime: number;
  approvedAmount: ApprovedAmount;
  transactionStatus: string;
  approvalCode: string;
  secure3dResponse: Secure3DResponse;
  schemeTransactionId: string;
  processor: Processor;
  authenticationResponse: AuthenticationResponse;
  error: boolean;
  declined: boolean;
  associationResponseCode: string;
  last4: string;
  total: string;
}

export interface ApprovedAmount {
  total: number;
  currency: string;
  components: Components;
}

export interface Components {
  subtotal: number;
}

export interface Referenciaprepago {
  costo: number;
  blnPagado: boolean;
  chrOrden: string;
  idPrepago: number;
  bln3dsForm: number;
  numTarjeta: string;
  intFKIdUser: number;
  chrNombreBanco: string;
}

export interface RechargeBalanceRequestDTO {
  costo: number;
  blnPagado: number;
  chrOrden: string;
  idPrepago: number;
  bln3dsForm: number;
  numTarjeta: string;
  intFKIdUser: number;
  chrNombreBanco: string;
}

export interface AddParkingPenDTO {
  Qr: number[];
  chrCorreo: string;
}

export interface ReportsAccountRequestDTO {
  startDate: string;
  endDate: string;
}

export interface ReportsAccountResponseDTO {
  result: string;
  rows: AccountReportRow[][];
}

export interface AccountReportRow {
  id: number;
  intIDUsuario: number;
  chrNombreUsuario: string;
  chrCorreo: string;
  cobroTarjeta: string;
  IngresoPluma_Total: number;
  IngresoPeriodo: number;
  EgresoPeriodo: number;
  Movimienos: number;
  Saldo: number;
  Adeudo: number;
  PrimerMovimiento: string;
  UltimoMovimiento: string;
  SaldoUsuario: number;
}

export interface ReportsClosingMonthRequestDTO {
  startDate: string;
  endDate: string;
}

export interface ReportsClosingMonthResponseDTO {
  result: string;
  rows: ClosingMonthReportRow[][];
}

export interface ClosingMonthReportRow {
  id: number;
  intIDUsuario: number;
  chrNombreUsuario: string;
  chrCorreo: string;
  SaldoInicial: number;
  TarjetaBancaria: string;
  DepositoTerminal: number;
  IngresoDelMes: number;
  EgresoDelMes: number;
  SaldoFinalDelMes: number;
  SaldoAcumulado: number;
  SaldoActual: number;
  Adeudo: number;
  chrTelefono: string;
}
